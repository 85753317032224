<template>
    <div class="w-full">
        <div class="rounded-3xl shadow-lg bg-white md:p-10 md:mx-10 mx-0 p-3" v-if="notice">
            <p class="text-3xl text-blue-600 text-center font-semibold">{{ notice.title }}</p>
            <p class="text-sm text-gray-600 text-center font-semibold mt-3">Date: {{ notice.displayDate }}</p>

            <div style="min-height: 150px" class="overflow-y-hidden">
                <p class="ck-content text-sm text-center font-medium leading-5 text-gray-500 mt-2 md:mt-5 md:px-10" v-html="notice.body"></p>
            </div>
        </div>

        <p class="mt-8 font-semibold text-3xl text-blue-600">Other Notices</p>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'notice-view',
        components: {
            
        },
        data(){
            return {
                notice: null
            }
        },
        computed: {
            noticeId(){
                return this.$route.params.id || null
            },
            ...mapGetters({
                notices: 'notices/notices',
            }),
        },
        methods: {
            openNotice(){
                if( this.noticeId &&  this.notices ) {
                    if(Array.isArray(this.notices)) {
                        const filtered = this.notices.filter((r) => r.id == this.noticeId );
                        this.notice = filtered[0];
                    }
                }
            }
        },
        created() {
            this.openNotice();
        },
        watch: {
            notices(){
                this.openNotice();
            },
            '$route.params.id'(){
                this.openNotice();
            }
        }
    };
</script>