<template>
    <container max-w="6xl">
        <p class="text-3xl text-center">Notice Board</p>

        <router-view/>

        <div class="relative mt-3">
            <div class="grid md:grid-cols-1 lg:grid-cols-3 gap-4 w-full">
                <notice-item v-for="(notice, index) in notices" :notice="notice" class="slide-item"/>
            </div>
        </div>
    </container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Container from '../../layouts/container.vue';
    import NoticeItem from './notice-item.vue';
    import NoticeView from './notice-view.vue';

    export default {
        name: 'notice',
        components: {
            Container, NoticeItem, NoticeView
        },
        data: {

        },
        computed: {
            ...mapGetters({
                notices: 'notices/notices',
            }),
        },
        created() {
            this.$store.dispatch('notices/noticeData');
        },
    }
</script>

<style scoped>

</style>
